import { useTranslation } from 'react-i18next';
import TourPopUp from "./TourPopUp";
import {Link} from "react-router-dom";

function Tours () {
    const { t } = useTranslation();
    const toursArr = [
        {
            name: 'tbilisiTour',
            popUpClass: 'tbilisi-tour'
        },
        {
            name: 'mountainTour',
            popUpClass: 'mountain-tour'
        },
        {
            name: 'wineryTour',
            popUpClass: 'winery-tour'
        },
        {
            name: 'adjaraTour',
            popUpClass: 'adjara-tour'
        }
    ]

    const tourItems = toursArr.map((item, index) =>
        <div key={index} className='tour-block'>
            <picture className='tour-photo'>
                <source type="image/webp" srcSet={`/img/tour(${index+1}).webp`}/>
                <img src={`/img/tour(${index+1}).jpg`} alt='tour-photo'/>
            </picture>
            <div>
                <div className='tour-name'>{t(item.name)}</div>
                <button onClick={()=>showTourPopUp(item.popUpClass)}>
                    <img className='tour-pop-up-btn' src={'/img/long-arrow-right.png'} alt='arrow'/>
                </button>
            </div>
            <div className={`${item.popUpClass} hidden pop-up-block`}><TourPopUp/></div>
        </div>
    )

    function hideTourPopUps(tourPopUp){
        const popUpBlocks = document.getElementsByClassName('pop-up-block');
        for (let block of popUpBlocks) {
            if(tourPopUp !== block) block.classList.add('hidden');
        }
    }

    function showTourPopUp(selectedPopUp){
        const tourPopUp = document.getElementsByClassName(selectedPopUp)[0];
        hideTourPopUps(tourPopUp);
        tourPopUp.classList.toggle('hidden');
    }

    document.addEventListener('click', function handleClickOutsideBox(event) {
        if (event.target.className !== 'tour-pop-up-btn') hideTourPopUps();
    });
    return(
        <div className='tours'>
            <div className='tours-description'>
                <div className='title-block'>
                    <h2 className='shadow-title'>{t('interesting')}</h2>
                    <h3 className='title'>{t('tours')}</h3>
                </div>
                <p>{t('toursDescription1')}</p>
                <p>{t('toursDescription2')}</p>
                <p>{t('toursDescription3')}</p>
                <Link className='primary-btn tours-xl' to={{
                    pathname: "",
                    hash: `#contactUs`,
                }}>{t('orderConsultationBtn')}</Link>
            </div>
            <div className="tours-photo">
                {tourItems}
            </div>
            <Link className='primary-btn tours-xs' to={{
                pathname: "",
                hash: `#contactUs`,
            }}>{t('orderConsultationBtn')}</Link>
        </div>
    )
}

export default Tours;