import { useTranslation } from 'react-i18next';
import Icons from "./Icons";

function TourPopUp () {
    const { t } = useTranslation();

    return(
        <div className='tour-pop-up'>
            <p>{t('chooseGeorgiaTour')}</p>
            <Icons/>
        </div>
    )
}

export default TourPopUp;