import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

function Banner () {
    const { t } = useTranslation();

    return(
        <div className='banner'>
            <div className='banner-text'>
                <h1 className='banner-title'>{t('Wedding')} <span className='title-italic'>"{t('fullyFledged')}"</span> {t('inSunnyGeorgia')}</h1>
                <div className='banner-description'>{t('consultation')}</div>
                <Link className='primary-btn' to={{
                    pathname: "",
                    hash: `#contactUs`,
                }}>{t('orderConsultationBtn')}</Link>
            </div>
            <picture className='banner-img'>
                <source type="image/webp" srcSet={"/img/banner.webp"}/>
                <img src={'/img/banner.png'} alt='wedding-photo'/>
            </picture>
        </div>
    )
}

export default Banner;