import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

function ContactUs (props) {
    const { t } = useTranslation();
    const photoArrClasses = [null, null, null, 's-size', 'm-size', 'l-size', 'xl-size', 'xl-size'];
    const path = props.secondaryPage? './../contactUs': 'contactUs';

    const instagramItems = photoArrClasses.map((classItem, index) =>
        <Link to={`${path}/instagram`} target="_blank" key={index} className={`instagram-photo ${classItem}`}>
            <picture className={'instagram-square-photo'}>
                <source type="image/webp" srcSet={`/img/instagram-photo(${index+1}).webp`}/>
                <img src={`/img/instagram-photo(${index+1}).png`} alt='instagram-photo'/>
            </picture>
            <img className='instagram-icon' src={'/img/instagram-icon.png'} alt='instagram-icon'/>
        </Link>
    )

    return(
        <div id='contactUs'>
            <div className='contact-us-centered'>
                <div className='title-block'>
                    <h2 className='shadow-title'>{t('dontHesitate')}</h2>
                    <h3 className='title'>{t('orderWedding')}</h3>
                </div>
                <div>{t('contactUsDescription')}</div>
                <div className='contactButtons'>
                    <Link className='primary-btn' to={`${path}/whatsapp`} target="_blank">
                        <img className='contact-icon whatsapp-icon' src={'/img/white-whatsapp.png'} alt='whatsapp'/>
                        <span className='desktop'>{t('writeToWhatsapp')}</span>
                        <span className='mobile'>{t('write')}</span>
                    </Link>
                    <Link className='primary-btn' to={`${path}/facebook`} target="_blank">
                        <img className='contact-icon facebook-icon' src={'/img/white-facebook.png'} alt='facebook'/>
                        <span className='desktop'>{t('writeToFacebook')}</span>
                        <span className='mobile'>{t('write')}</span>
                    </Link>
                </div>
            </div>
            <div className='instagram'>
                {instagramItems}
            </div>
        </div>
    )
}

export default ContactUs;