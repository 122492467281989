import { BackgroundImage } from 'react-image-and-background-image-fade'

function DesktopGallery (props) {
    const block = props.block;
    const openLightboxOnSlide = props.openLightboxOnSlide;
    const imageNumbers = [2, 3, 4, 5, 6];
    const imageBlocks = imageNumbers.map((item) =>
        <div key={item} className={`img${item+1} common-image`}>
            <BackgroundImage className='gallery-block'
                             src={`/${block[item].path}`}
                             width='100%'
                             height='100%'
                             onClick={() => openLightboxOnSlide(block[item].index)}>
            </BackgroundImage>
        </div>
    )

    return(
        <div key={block[0].path} className='column-gallery desktop'>
            <div className='gallery-block row-photo-block'>
                <div className='img1 common-image'>
                    <BackgroundImage className='gallery-block'
                                     src={`/${block[0].path}`}
                                     width='100%'
                                     height='100%'
                                     onClick={() => openLightboxOnSlide(block[0].index)}>
                    </BackgroundImage>
                </div>
                <div className="img2 common-image">
                    <BackgroundImage className='gallery-block'
                                     src={`/${block[1].path}`}
                                     alt={'gallery'}
                                     width='100%'
                                     height='100%'
                                     onClick={() => openLightboxOnSlide(block[1].index)}>
                    </BackgroundImage>
                </div>
            </div>
            {imageBlocks}
        </div>
    )
}

export default DesktopGallery;