import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';
import {useEffect, useRef} from "react";

function SliderWorkItems () {
    const { t, i18n } = useTranslation();
    const sliderRef = useRef();

    let settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        rtl: i18n.language === 'he',
    };

    useEffect(()=>{
        if(i18n.language === 'he'){
            sliderRef.current.slickGoTo(6);
        }
    })


    const workItemNumbers = ['02', '03', '04', '05', '06', '07', '08', '09', '10'];
    const sliderWorkItems = workItemNumbers.map((item, index) =>
        <div key={index} className='how-we-work-block'>
            <div className='bronze-block'>
                <hr className='dotted-line'/>
                <div className='number-item'>{item}</div>
                <hr className='dotted-line'/>
            </div>
            <div className='text-item'>{t('workStages', { returnObjects: true })[index+1]}</div>
        </div>
    )

    return(
        <>
            <Slider {...settings} ref={sliderRef}>
                <div className='how-we-work-block'>
                    <div className='bronze-block first-carousel-block'>
                        <hr className='dotted-line rtl-visible'/>
                        <div className='number-item'>01</div>
                        <hr className='dotted-line ltr-visible'/>
                    </div>
                    <div className='text-item'>{t('workStages', { returnObjects: true })[0]}</div>
                </div>
                {sliderWorkItems}
                <div className='how-we-work-block'>
                    <div className='bronze-block last-carousel-block'>
                        <hr className='dotted-line ltr-visible'/>
                        <div className='number-item'>11</div>
                        <hr className='dotted-line rtl-visible'/>
                    </div>
                    <div className='text-item'>{t('workStages', { returnObjects: true })[10]}</div>
                </div>
            </Slider>
        </>
    )
}

export default SliderWorkItems;