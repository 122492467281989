import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import PackageBanner from "./PackageBanner";
import CostComponents from "./CostComponents";
import ExtraServices from "./ExtraServices";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {getLanguage, setLanguage} from "../helpers/language";

function NonCeremonialSigning () {
    const location = useLocation();
    const lang = getLanguage();
    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
            window.scrollTo({top:0,left:0})
        }
    }, [location])

    useEffect(() => {
        setLanguage(lang);
    }, [lang])

    return(
        <>
            <Header secondaryPage={true}/>
            <PackageBanner isCeremonial={false}/>
            <CostComponents isCeremonial={false}/>
            <ExtraServices/>
            <ContactUs secondaryPage={true}/>
            <Footer secondaryPage={true}/>
        </>
    )
}

export default NonCeremonialSigning;