import {HashRouter, Route, Routes} from "react-router-dom";
import Home from "./Home";
import CeremonialSigning from "./CeremonialSigning";
import NonCeremonialSigning from "./NonCeremonialSigning";
import BackToTopButton from "./BackToTopButton";
import Messenger from "./Messenger";

function App() {
  return (
    <>
        <HashRouter>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/contactUs/whatsapp" element={<Messenger messenger={'whatsapp'}/>}/>
                <Route path="/contactUs/facebook" element={<Messenger messenger={'facebook'}/>}/>
                <Route path="/contactUs/telegram" element={<Messenger messenger={'telegram'}/>}/>
                <Route path="/contactUs/instagram" element={<Messenger messenger={'instagram'}/>}/>
                <Route path="/ceremonial-signing" element={<CeremonialSigning/>}/>
                <Route path="/non-ceremonial-signing" element={<NonCeremonialSigning/>}/>
            </Routes>
        </HashRouter>
        <BackToTopButton/>
    </>
  );
}

export default App;