import SliderWorkItems from "./SliderWorkItems";
import { useTranslation } from 'react-i18next';

function Benefits () {
    const { t } = useTranslation();

    const workItemNumbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
    const workItems = workItemNumbers.map((item, index) =>
        <div key={index} className='how-we-work-block'>
            <div className='bronze-block number-item'>{item}</div>
            <div className='text-item'>{t('workStages', { returnObjects: true })[index]}</div>
        </div>
    )
    return(
        <div id='benefits'>
            <div className='title-block'>
                <h2 className='shadow-title'>{t('ourBenefits')}</h2>
                <h3 className='title'>{t('perfectWeddingInThreeDays')}</h3>
            </div>
            <div className='benefits-container'>
                <div className='benefit-block'>
                    <span className='benefit-title'>{t('legalization')}</span>
                    <div className='benefit-description'>{t('legalizationDescription')}</div>
                </div>
                <div className='benefit-block'>
                    <span className='benefit-title'>{t('formalizationSpeed')}</span>
                    <div className='benefit-description'>{t('formalizationSpeedDescription')}</div>
                </div>
                <div className='benefit-block'>
                    <span className='benefit-title'>{t('individualApproach')}</span>
                    <div className='benefit-description'>{t('individualApproachDescription')}</div>
                </div>
            </div>
            <div className='how-we-work'>
                <div className='title-block'>
                    <h2 className='shadow-title'>{t('cooperationStages')}</h2>
                    <h3 className='title'>{t('whenContacting')}</h3>
                </div>
                <div className='how-we-work-container'>
                    <div className='slider-work-items'><SliderWorkItems/></div>
                    <div className='column-work-items'>{workItems}</div>
                </div>
            </div>
        </div>
    )
}

export default Benefits;