import { useTranslation } from 'react-i18next';

function ExtraServices () {
    const { t } = useTranslation();

    return(
        <div id='extraServices' className='extra-services'>
            <div className='title-block'>
                <h2 className='shadow-title'>{t('weProvide')}</h2>
                <h3 className='title'>{t('extraServices')}</h3>
            </div>
            <div className='extra-services-description'>{t('extraServicesDescription')}</div>
            <div className='services-container'>
                <div className='service-mini-block'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(1).webp`}/>
                            <img src={'/img/service(1).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('carInTheWedding')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 70</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-mini-block small-screen-reverse'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(2).webp`}/>
                            <img src={'/img/service(2).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('makeupAndHaircut')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 120</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-mini-block medium-screen-reverse'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(3).webp`}/>
                            <img src={'/img/service(3).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('flowersAndBoutonniere')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 70</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-block empty-service'> </div>
                <div className='service-mini-block medium-screen-reverse small-screen-reverse'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(4).webp`}/>
                            <img src={'/img/service(4).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('outdoorCeremony')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 500</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-mini-block'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(5).webp`}/>
                            <img src={'/img/service(5).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block long'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('nationalCostumesRent')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 200</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-mini-block small-screen-reverse'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(6).webp`}/>
                            <img src={'/img/service(6).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('photographer')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 150</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-mini-block medium-screen-reverse'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(7).webp`}/>
                            <img src={'/img/service(7).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('videographer')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 200</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
                <div className='service-mini-block medium-screen-reverse small-screen-reverse'>
                    <div className='service-block'>
                        <picture>
                            <source type="image/webp" srcSet={`/img/service(8).webp`}/>
                            <img src={'/img/service(8).png'} alt='wedding-photo'/>
                        </picture>
                    </div>
                    <div className='service-block service-text-block'>
                        <div className='service-title'>{t('service')}</div>
                        <div className='service-name'>{t('tours')}</div>
                        <div className='service-price'>{t('startingFrom')}
                            <span className='service-price-number'> 35</span>
                            <span className='prata-font'> €</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExtraServices;