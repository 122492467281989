function BackToTopButton() {
    function backToTop(){
        window.scrollTo({top:0,left:0, behavior: 'smooth'})
    }
    return (
        <button className='back-to-top-btn' onClick={()=>backToTop()}>
            <img src={'/img/angle-up.svg'} alt={'up'} className={'arrow-up'}/>
        </button>
    );
}

export default BackToTopButton;