import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { hydrateRoot } from 'react-dom/client';

import ('./index.css');
import ('./css/header.css');
import ('./css/banner.css');
import ('./css/aboutUs.css');
import ('./css/benefits.css');
import ('./css/contactUs.css');
import ('./css/footer.css');
import ('./css/packagesPages.css');
import ('./css/photoGallery.css');
import ('./css/reviews.css');
import ('./css/servicePackages.css');
import ('./css/tours.css');

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

if (rootElement.hasChildNodes()) {
    hydrateRoot(rootElement, <App />);
} else {
    root.render(
        // <React.StrictMode>
        <App />
        // </React.StrictMode>
    );
}
reportWebVitals();
