import NavMenu from "./NavMenu";
import {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import {getLanguage, setLanguage} from "../helpers/language";

function Header (props) {
    const { t } = useTranslation();
    const lang = getLanguage();
    const [selectedLanguage, setSelectedLanguage] = useState(lang);
    const secondaryPage = props.secondaryPage;

    useEffect(() => {
        setSelectedLanguage(lang);
    }, [lang])

    function toggleMenu(){
        const menu = document.getElementsByClassName('nav-menu')[0];
        const openMenuBtn = document.getElementsByClassName('open-menu-btn')[0];
        const closeMenuBtn = document.getElementsByClassName('close-menu-btn')[0];
        menu.classList.toggle('active-menu');
        openMenuBtn.classList.toggle('hidden');
        closeMenuBtn.classList.toggle('hidden');
    }

    function toggleLanguageList(){
        document.getElementsByClassName('language-list')[0].classList.toggle('hidden');
    }

    function setPath(lang) {
        const path = window.location.href;
        const newPath = path.replace(window.location.pathname, `/${lang}`);
        window.history.replaceState(null, "New Page Title", newPath);
        setLanguage(lang);
    }

    function selectLanguage(language){
        setPath(language);
        setSelectedLanguage(language);
        document.getElementsByClassName('language-list')[0].classList.add('hidden');
        if(language === 'he'){
            document.body.classList.add('rtl');
        } else{
            document.body.classList.remove('rtl');
        }
    }
    return(
        <div className="header-container">
            <img className="logo-image" src={"/img/logo.png"} alt="logo"/>
            <button className="menu-icon hidden" onClick={() => toggleMenu()}>
                <img className='open-menu-btn' src={"/img/menu.svg"} alt="menu"/>
                <img className='close-menu-btn hidden' src={"/img/close-icon.png"} alt="close"/>
            </button>
            <NavMenu toggleMenu={toggleMenu} secondaryPage={secondaryPage}>
                <div className="select-language">
                    <div className='selected-language' onClick={()=>toggleLanguageList()}>{selectedLanguage}
                        <img className='arrow-down' src={'/img/arrow-down.svg'} alt='arrow'/>
                    </div>
                    <ul className='language-list hidden'>
                        <a role="button" className='language-item' onClick={()=>selectLanguage('en')}>{t('en')}</a>
                        <a role="button" className='language-item' onClick={()=>selectLanguage('ru')}>{t('ru')}</a>
                        {/*<Link className='language-item' to={setPath('he')} onClick={()=>selectLanguage('he')}>{t('he')}</Link>*/}
                    </ul>
                </div>
            </NavMenu>
        </div>
    )
}

export default Header;