import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import {useEffect} from "react";

function CostComponents (props) {
    const { t, i18n } = useTranslation();

    const isCeremonial = props.isCeremonial;
    const costList = isCeremonial? t('ceremonialCostItems', { returnObjects: true }): t('nonCeremonialCostItems', { returnObjects: true });
    const costItems = costList.map((item, index) =>
        <li className='cost-item' key={index}>{item}</li>
    )
    let pathname;

    useEffect(() => {
        pathname = `${i18n.language}`
    }, [])

    return(
        <div className='cost-components'>
            <div className='cost-components-container'>
                <picture className='cost-component-photo'>
                    <source type="image/webp" srcSet={isCeremonial? '/img/cost(1).webp': '/img/cost(3).webp'}/>
                    <img alt='wedding-photo' src={isCeremonial? '/img/cost(1).png': '/img/cost(3).png'}/>
                </picture>
                <div className='cost-component-block'>
                    <div className='title-block'>
                        <h2 className='shadow-title'>{t('uniqueness')}</h2>
                        <h3 className='title'>{t('whatIsIncluded')}</h3>
                    </div>
                    <div className='cost-text-block'>
                        <ul className='cost-items-list'>{costItems}</ul>
                        <div>{t('addCostInfo')} <b>+140€</b></div>
                        <ul className='cost-items-list'><li className='cost-item'>{t('airportTransfer')}</li></ul>
                    </div>
                    <Link className='primary-btn' to={{
                        hash: `#extraServices`,
                    }}>{t('extraServices')}</Link>
                </div>
            </div>
            <div className="cost-components-container">
                <picture className='cost-component-photo small-screen'>
                    <source type="image/webp" srcSet={isCeremonial? '/img/cost(2).webp': '/img/cost(4).webp'}/>
                    <img src={isCeremonial? '/img/cost(2).png': '/img/cost(4).png'} alt='wedding-photo'/>
                </picture>
                <div className='cost-component-block'>
                    <div className='cost-paragraphs'>
                        <p>{t('packageParagraph1')}</p>
                        <p>{t('packageParagraph2')} <b>+25€</b>, {t('ifHebrew')} <b>+40€</b></p>
                        <p><b>{t('packageParagraph3')}</b></p>
                        <p>{t('packageParagraph4')}</p>
                        <p>{t('packageParagraph5')}</p>
                    </div>
                    <Link className='primary-btn' to={{
                        pathname: pathname,
                        hash: `#contactUs`,
                    }}>{t('contactUs')}</Link>
                </div>
                <picture className='cost-component-photo l-xs-screen'>
                    <source type="image/webp" srcSet={isCeremonial? '/img/cost(2).webp': '/img/cost(4).webp'}/>
                    <img src={isCeremonial? '/img/cost(2).png': '/img/cost(4).png'} alt='wedding-photo'/>
                </picture>
            </div>
        </div>
    )
}

export default CostComponents;