import NavMenu from "./NavMenu";
import { useTranslation } from 'react-i18next';

function Footer (props) {
    const { t } = useTranslation();

    return(
        <div id='footer'>
            <img className='footer-logo' src={'/img/logo.png'} alt='logo'/>
            <NavMenu secondaryPage={props.secondaryPage}/>
            <div className='rights-reserved'>{t('allRightsReserved')}</div>
        </div>
    )
}

export default Footer;