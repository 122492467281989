import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

function AboutUs () {
    const { t } = useTranslation();

    return(
        <div id='aboutUs'>
            <div className='about-us-difference'>
                <picture className='about-us-img'>
                    <source type="image/webp" srcSet={`/img/about-us-top.webp`}/>
                    <img src={'/img/about-us-top.png'} alt='wedding-photo'/>
                </picture>
                <div className='about-us-block'>
                    <div className='title-block'>
                        <h2 className='shadow-title'>{t('uniqueness')}</h2>
                        <h3 className='title'>{t('differenceTitle')}</h3>
                    </div>
                    <div className='flex-space-between'>
                        <div className='about-us-text-block'>{t('difference1')}</div>
                        <div className='about-us-text-block'>{t('difference2')}</div>
                    </div>
                    <Link className='primary-btn' to={{
                        pathname: "",
                        hash: `#servicePackages`,
                    }}>{t('choosePackage')}</Link>
                </div>
            </div>
            <div className='about-us-approach'>
                <picture className='about-us-img small-screen'>
                    <source type="image/webp" srcSet={`/img/about-us-bottom.webp`}/>
                    <img src={'/img/about-us-bottom.png'} alt='wedding-photo'/>
                </picture>
                <div className='about-us-block'>
                    <div className='title-block'>
                        <h2 className='shadow-title'>{t('individual')}</h2>
                        <h3 className='title'>{t('approachTitle')}</h3>
                    </div>
                    <div className='flex-space-between'>
                        <div className='about-us-text-block'>{t('approach1')}</div>
                        <div className='about-us-text-block'>{t('approach2')}</div>
                    </div>
                    <Link className='primary-btn' to={{
                        pathname: "",
                        hash: `#contactUs`,
                    }}>{t('contactUs')}</Link>
                </div>
                <picture className='about-us-img l-xs-screen'>
                    <source type="image/webp" srcSet={`/img/about-us-bottom.webp`}/>
                    <img src={'/img/about-us-bottom.png'} alt='wedding-photo'/>
                </picture>
            </div>
        </div>
    )
}

export default AboutUs;