import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en.json";
import ru from "./languages/ru.json";
import he from "./languages/he.json";
const resources = {
    en,
    ru,
    he
};

const pathname = window.location.pathname;
const currentLanguage = pathname.substring(1, 3);

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: currentLanguage,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;