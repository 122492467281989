import FsLightbox from "fslightbox-react";
import {useEffect, useLayoutEffect, useState} from "react";
import MobileGallery from "./MobileGallery";
import DesktopGallery from "./DesktopGallery";
import { useTranslation } from 'react-i18next';

function PhotoThemeBlock (props) {
    const { t } = useTranslation();
    const [isBtnEnabled, setIsBtnEnabled] = useState(true);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const [blocksQuantity, setBlocksQuantity] = useState({
        quantity: 1,
        startNumber: 1,
        endNumber: 8,
    });
    const [sources, setSources] = useState([]);
    const [sourceObjects, setSourceObjects] = useState([]);
    const [galleryBlocks, setGalleryBlocks] = useState([]);
    const mobileScreenWidth = 480;

    let galleryContainer;

    let windowScreen = window.screen.width;
    if(windowScreen >= mobileScreenWidth){
        galleryContainer = galleryBlocks.map((block, index) =>
            <DesktopGallery key={index} block={block} openLightboxOnSlide={openLightboxOnSlide}/>)
    } else{
        galleryContainer = galleryBlocks.map((block, index) =>
            <MobileGallery key={index} block={block} openLightboxOnSlide={openLightboxOnSlide}/>)
    }

    useLayoutEffect(()=>{
        setIsBtnEnabled(true);
        setBlocksQuantity({
            quantity: 1,
            startNumber: 1,
            endNumber: windowScreen >= mobileScreenWidth? 8: 6,
        });
        setSources([]);
        setSourceObjects([]);
        setGalleryBlocks([]);
    }, [props.activeTab])

    useEffect(() =>{
        let i, imgFolder, sourcesArr = [], sourceObjectsArr = [], galleryBlocksArr = [];
        imgFolder = defineIngFolder(props.activeTab.id);
        for(i=blocksQuantity.startNumber; i<blocksQuantity.endNumber; i+=1){
            let imgPath=`${imgFolder}/photo(${i}).jpg`;
            sourceObjectsArr.push({
                path: imgPath,
                index: i
            });
            sources.push(imgPath);
        }
        setSources(sources.concat(sourcesArr));
        setSourceObjects(sourceObjects.concat(sourceObjectsArr));
        galleryBlocksArr.push(sourceObjectsArr);
        setGalleryBlocks(galleryBlocks.concat(galleryBlocksArr));
        checkIfPhotosExist(imgFolder);
    }, [blocksQuantity]);

    function defineIngFolder(activeTabId) {
        switch(activeTabId) {
            case 1:
                return `img/gallery/walk`;
            case 2:
                return `img/gallery/mountain`;
            case 3:
                return `img/gallery/registry-office`;
            case 4:
                return `img/gallery/photo-day`;
        }
    }

    function checkIfPhotosExist(imgFolder){
        const lastImage = new Image();
        const nextImage = new Image();
        lastImage.src = `/${imgFolder}/photo(${sources.length}).jpg`;
        nextImage.src = `/${imgFolder}/photo(${sources.length+1}).jpg`;
        //disable button 'more photos' if there is no more images in the folder
        lastImage.onerror = () => setIsBtnEnabled(false);
        nextImage.onerror = () => setIsBtnEnabled(false);
    }

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    function expandBlocks(prevState) {
        const stepSize = windowScreen >= mobileScreenWidth? 7: 5;
        setBlocksQuantity({
            quantity:prevState.quantity+=1,
            startNumber: prevState.startNumber+=stepSize,
            endNumber: prevState.endNumber+=stepSize,
        });
    }

    return(
        <div className='photo-theme-block'>
            {galleryContainer}
            <FsLightbox
                toggler={lightboxController.toggler}
                slide={lightboxController.slide}
                sources={sources}
                key={blocksQuantity.quantity}
            />
            <button disabled={!isBtnEnabled} className='primary-btn' onClick={() => expandBlocks(blocksQuantity)}>{t('uploadMorePhotos')}</button>
        </div>
    )
}

export default PhotoThemeBlock;