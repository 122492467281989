import {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import PhotoThemeBlock from "./PhotoThemeBlock";
import i18n from "i18next";
function PhotoGallery () {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState({id: 1, name: t('photoWalk')});
    const tabsArr = ['photoWalk', 'mountainPhotoShoot', 'registryOfficesPhoto', 'photoDayGeorgia'];

    const tabsItem = tabsArr.map((name, index) =>
        <li key={index} className={activeTab.id === index+1? 'selected-tab': null}
            onClick={() => changeActiveTab(index+1, t(name))}>{t(name)}
        </li>
    )
    function changeActiveTab(id, name){
        setActiveTab({id, name});
        togglePhotoTabs();
    }

    function togglePhotoTabs(){
        const photoTabs = document.getElementsByClassName('photo-tabs')[0];
        photoTabs.classList.toggle('visible-tab');
    }

    useEffect(()=>{
        setActiveTab((prevState) => ({
            ...prevState,
            name: t(tabsArr[activeTab.id-1]),
        }));
    }, [i18n.language])

    return(
        <div id='photoGallery'>
            <div className='title-block'>
                <h2 className='shadow-title'>{t('ourPhotoGallery')}</h2>
                <h3 className='title'>{t('joyfulCouples')}</h3>
            </div>
            <div className='gallery-description'>{t('photoGalleryDescription')}</div>
            <div className="photos">
                <div className='photo-tabs-dropdown hidden' onClick={()=>togglePhotoTabs()}>
                    {activeTab.name}
                    <img className='arrow-down' src={'/img/arrow-down.svg'} alt='arrow'/>
                </div>
                <ul className='photo-tabs'>
                    {tabsItem}
                </ul>
                <PhotoThemeBlock activeTab={activeTab}/>
            </div>
            <div className='gallery-footer'>
                <div className="motto">{t('motto')}</div>
                <div className="stats">
                    <div className="stats-item ceremony">
                        <img className='item-icon' src={'/img/ceremony-icon.png'} alt='icon'/>
                        <div className='item-number'>365</div>
                        <div className='item-name'>{t('ceremonies')}</div>
                    </div>
                    <div className="stats-item couple">
                        <img className='item-icon' src={'/img/couple-icon.png'} alt='icon'/>
                        <div className='item-number'>80</div>
                        <div className='item-name'>{t('beautifulCouples')}</div>
                    </div>
                    <div className="stats-item bouquet">
                        <img className='item-icon' src={'/img/bouquet-icon.png'} alt='icon'/>
                        <div className='item-number'>160</div>
                        <div className='item-name'>{t('bestBouquets')}</div>
                    </div>
                    <div className="stats-item glasses">
                        <img className='item-icon' src={'/img/glasses-icon.png'} alt='icon'/>
                        <div className='item-number'>700</div>
                        <div className='item-name'>{t('champagneBottles')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhotoGallery;