import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {getLanguage, setLanguage} from "../helpers/language";

const Messenger = (props) => {
    const { t } = useTranslation();
    const lang = getLanguage();
    let messenger = props.messenger;

    useEffect(() => {
        setLanguage(lang);
        switch(messenger) {
            case 'whatsapp':
                window.location.href = "https://wa.me/995593744041";
                break;
            case 'facebook':
                window.location.href = "https://www.facebook.com/irinasakhokia";
                break;
            case 'telegram':
                window.location.href = "https://t.me/weddinggeorgia";
                break;
            case 'instagram':
                window.location.href = "https://www.instagram.com/iren_studia/";
                break
        }
    }, [lang])

    return(
        <div className={'redirected-message'}>
            {t('redirectingToMessengers')}
            <img className={'redirected-icon'} src={`/img/${messenger}.png`} alt={messenger}/>
        </div>
    )
}

export default Messenger;