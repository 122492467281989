import {Link} from "react-router-dom";

function Icons (props) {
    const path = props.secondaryPage? './../contactUs': 'contactUs';
    return(
        <div className='messengers'>
            <Link to={`${path}/telegram`} target="_blank">
                <img className="social-media-icon" src={'/img/telegram.png'} alt='telegram'/>
            </Link>
            <Link to={`${path}/whatsapp`} target="_blank">
                <img className="social-media-icon middle-social-media-icon" src={'/img/whatsapp.png'} alt='whatsapp'/>
            </Link>
            <Link  to={`${path}/facebook`} target="_blank">
                <img className="social-media-icon" src={'/img/facebook.png'} alt='facebook'/>
            </Link>
        </div>
    )
}

export default Icons;