import Header from './Header';
import AboutUs from './AboutUs';
import Benefits from './Benefits';
import ServicePackages from './ServicePackages';
import PhotoGallery from './PhotoGallery';
import Reviews from './Reviews';
import ContactUs from './ContactUs';
import Footer from './Footer';
import Banner from "./Banner";
import Tours from "./Tours";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {getLanguage, setLanguage} from "../helpers/language";

function Home () {
    const location = useLocation();
    const lang = getLanguage();
    useEffect(()=> {
        if(lang){
            setLanguage(lang);
            scrollToElement();
        } else {
            window.history.replaceState(null, "Fast Wedding Georgia", "/ru");
            setLanguage('ru');
        }

    }, [location])

    function scrollToElement() {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem){
                if(document.readyState === 'complete'){
                    setTimeout(()=>{elem.scrollIntoView({behavior: "smooth"})}, 200);
                } else{
                    window.onload = () => {
                        elem.scrollIntoView({behavior: "smooth"});
                    }
                }
            }
        } else {
            window.scrollTo({top:0,left:0})
        }
    }

    return(
        <>
            <Header/>
            <Banner/>
            <AboutUs/>
            <Benefits/>
            <ServicePackages/>
            <PhotoGallery/>
            <Reviews/>
            <Tours/>
            <ContactUs/>
            <Footer/>
        </>
    )
}

export default Home;