import { useTranslation } from 'react-i18next';

function Reviews () {
    const { t } = useTranslation();

    return(
        <div id='reviews'>
            <div className="clients-photo">
                <picture className='client-photo'>
                    <source type="image/webp" srcSet={'/img/review.webp'}/>
                    <img src={'/img/review.jpg'} alt='photo review'/>
                </picture>
            </div>
            <div className="client-review">
                <div className="review-header">
                    <h3 className='title'>{t('customerReviews')}</h3>
                    <div>{t('motto')}</div>
                </div>
                <img src={'/img/quotes.png'} alt='quotes' className='quotes'/>
                <div className='review-text'>
                    <p>{t('reviewText1')}</p>
                    <p>{t('reviewText2')}</p>
                </div>
                <picture className='small-couple-photo hidden'>
                    <source type="image/webp" srcSet={'/img/small-circle-review.webp'}/>
                    <img src={'/img/small-circle-review.png'} alt='wedding photo'/>
                </picture>
                <div className='caption'>{t('georgAndMaria')}
                    <div className='review-date'>{t('tbilisi')} 12 / 06 / 2021</div>
                </div>
            </div>

        </div>
    )
}

export default Reviews;