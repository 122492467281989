import {Image} from 'react-image-and-background-image-fade'

function MobileGallery (props) {
    const block = props.block;
    const openLightboxOnSlide = props.openLightboxOnSlide;
    const imageNumbers = [0, 1, 2, 3, 4];
    const imageBlocks = imageNumbers.map((item) =>
        <Image key={item}
               alt={'gallery'}
               className='gallery-block'
               src={`/${block[item].path}`}
               width='100%'
               height='auto'
               onClick={() => openLightboxOnSlide(block[item].index)}>
        </Image>
    )
    return(
        <div key={block[0].path} className='column-gallery mobile'>
            {imageBlocks}
        </div>
    )
}

export default MobileGallery;