import Icons from "./Icons";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function NavMenu (props) {
    const { t } = useTranslation();
    const menuItemsArray = ['aboutUs', 'benefits', 'servicePackages', 'photoGallery', 'reviews', 'contactUs'];
    const menuItems = menuItemsArray.map((menuItem, index) =>
        <li className={`menu-item ${index===0? "active": ""}`} key={index}>
            <Link to={{
                pathname: "/",
                hash: `#${menuItem}`,
            }} onClick={()=>closeMenu()}>{t(menuItem)}</Link>
        </li>
    )
    function closeMenu(){
        if(props.toggleMenu){
            props.toggleMenu();
        }
    }
    return(
        <div className='nav-menu'>
            <ul className="menu">
                {menuItems}
            </ul>
            {props.children}
            <Icons secondaryPage={props.secondaryPage}/>
        </div>
    )
}

export default NavMenu;