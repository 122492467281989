import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function ServicePackages () {
    const { t } = useTranslation();

    return(
        <div id='servicePackages'>
            <div className='title-block'>
                <h2 className='shadow-title'>{t('goodOffer')}</h2>
                <h3 className='title'>{t('weddingCost')}</h3>
            </div>
            <div className='packages-description'>{t('costDescription')}</div>
            <div className='packages-block'>
                <div className="packages-block-item">
                    <picture className='package-img'>
                        <source type="image/webp" srcSet={'/img/non-ceremonial-signing.webp'}/>
                        <img src={'/img/non-ceremonial-signing.png'} alt='not ceremonial wedding'/>
                    </picture>
                    <span className='package-size'>{t('packageMin')}</span>
                    <h4 className='package-name'>{t('nonCeremonialSigning')}</h4>
                    <div className='price-block'>{t('from')}
                        <span className='price'> 470</span>
                        <span className='currency'> €</span>
                    </div>
                    <div className='package-description'>{t('minPackageDescription')}</div>
                    <Link className='primary-btn' to='non-ceremonial-signing'>{t('moreAboutPackage')}</Link>
                </div>
                <div className="packages-block-item">
                    <picture className='package-img'>
                        <source type="image/webp" srcSet={'/img/ceremonial-signing.webp'}/>
                        <img src={'/img/ceremonial-signing.png'} alt='ceremonial wedding'/>
                    </picture>
                    <span className='package-size'>{t('packageMax')}</span>
                    <h4 className='package-name'>{t('ceremonialSigning')}</h4>
                    <div className='price-block'>{t('from')}
                        <span className='price'> 570</span>
                        <span className='currency'> €</span>
                    </div>
                    <div className='package-description'>{t('maxPackageDescription')}</div>
                    <Link className='primary-btn' to='ceremonial-signing' >{t('moreAboutPackage')}</Link>
                </div>
            </div>
        </div>
    )
}

export default ServicePackages;