import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import {useEffect} from "react";

function PackageBanner (props) {
    const { t, i18n } = useTranslation();
    let pathname;

    useEffect(() => {
        pathname = `${i18n.language}`
    }, [])

    const isCeremonial = props.isCeremonial;
    return(
        <div className='banner package'>
            <div className='banner-text'>
                <h1 className='banner-title'>
                    <div className='package-title'>{t('package')}</div>
                    {isCeremonial? t('ceremonialSigning'): t('nonCeremonialSigning')}
                </h1>
                <div className='banner-description'>
                    {isCeremonial? t('maxPackageDescription'): t('minPackageDescription')}
                </div>
                <div className='package-cost'>
                    <b>{isCeremonial? t('ceremonialCost'): t('nonCeremonialCost')}:</b>
                    <div className='cost-options'>
                        <div className='cost-block'>
                            <div className='bronze-days'>{t('weekdays')}</div>
                            <div className='price-item'>{t('from')} {isCeremonial? 570: 470}€</div>
                        </div>
                        <div className='cost-block'>
                            <div className='bronze-days'>{t('weekends')}</div>
                            <div className='price-item'>{t('from')} {isCeremonial? 670: 570}€</div>
                        </div>
                    </div>
                </div>
                <Link className='primary-btn' to={{
                    pathname: pathname,
                    hash: `#contactUs`,
                }}>{t('orderConsultationBtn')}</Link>
            </div>
            <picture className='banner-img'>
                <source type="image/webp" srcSet={isCeremonial? '/img/ceremonial-banner.webp': '/img/non-ceremonial-banner.webp'}/>
                <img alt='banner' src={isCeremonial? '/img/ceremonial-banner.png': '/img/non-ceremonial-banner.png'}/>
            </picture>
        </div>
    )
}

export default PackageBanner;